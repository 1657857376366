import React, { useEffect, useState, useRef } from "react";
import InputSearchComponent from "../../components/inputSearch/InputSearchComponent";
import ChatListComponent from "../../components/list/ChatListComponent";
import SectionComponent from "../../components/section/SectionComponent";
import { AppContext } from "../../App";
import styles from "./styles.module.css";
import loaderImage from '../../assets/images/gif/loader.gif'; // Import your GIF file

function ChatAIScreen({
  searchText,
  placeholder,
  sendIcon,
  data,
  isLoading,
  apiError,
  onSearchTextChange,
  onSubmitButtonTapped,
  onSectionItemClick,
  sections,
}) {
  const { headerHeight } = React.useContext(AppContext);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1100);

  const listRef = useRef(null);
  const textRef = useRef(null);

  const onInputTextChange = (event) => {
    handleTextAreaHeight(event);
    onSearchTextChange(event);
  };

  const onHandleKeyDown = (event) => {
    handleTextAreaHeight(event);
  };

  const handleTextAreaHeight = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (searchText.length === 0) {
      if (window.innerWidth <= 768) {
        textRef.current.style.height = "21px";
      } else {
        textRef.current.style.height = "25px";
      }
    }
  }, [searchText]);

  useEffect(() => {
    textRef.current?.focus();
  }, []);

  useEffect(() => {
    handleScrollToEnd();
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1100);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrollToEnd = () => {
    const lastListItem = listRef.current;
    if (lastListItem) {
      lastListItem.scrollIntoView({ block: "nearest", inline: "nearest" });
    }
  };

  return (
    <div
      className={styles.container}
      style={{ "--dynamic-height": `${headerHeight}px` }}
    >
      <div className={styles.appContainer}>
        <div className={styles.chatListContainer}>
          <ChatListComponent data={data} />
          {isLoading && (
            <div className={styles.loaderContainer}>
              <img src={loaderImage} alt="GIF"  className={styles.loader}/>
            </div>
          )}
          {apiError && (
            <div className="error">{apiError.message}</div>
          )}
          <div ref={listRef} />
        </div>
        <InputSearchComponent
          textRef={textRef}
          searchText={searchText}
          placeholder={placeholder}
          onSearchTextChange={onInputTextChange}
          onHandleKeyDown={onHandleKeyDown}
          onSubmitButtonTapped={onSubmitButtonTapped}
          refresh={isLoading}
          icon={sendIcon}
        />
      </div>
      {isWideScreen && (
        <div className={styles.hintSections}>
          <ul className={styles.hintList}>
            {sections.map((section, idx) => (
              <li key={`Section_${idx}`}>
                <SectionComponent
                  style={styles.section}
                  key={`Item_${idx}`}
                  section={section}
                  isChatStyle={true}
                  onSectionItemClick={onSectionItemClick}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ChatAIScreen;
