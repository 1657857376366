import React, { useState, useEffect, useLayoutEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import FooterComponent from "./components/headerFooter/FooterComponent";
import HomePage from "./screens/home/HomePage";
import ChatPage from "./screens/chatPage/ChatPage";
import CaseStudies from "./screens/caseStudy/CaseStudies/CaseStudies";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    },
  },
});

export const AppContext = React.createContext();

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [sections, setSections] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);
  const [isNeedToAuth, setNeedToAuth] = useState(false);
  const [isExploreCaseStudies, setExploreCaseStudies] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/chat",
      element: <ChatPage />,
    },
    {
      path: "/case-studies",
      element: <CaseStudies />,
    },
  ]);

  useLayoutEffect(() => {
    function updateSize() {
      setHeaderHeight(document.getElementById("masthead").offsetHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    var data = require("../src/assets/json/sections.json");
    setSections(data.sections);
    setCaseStudies(data.caseStudies);
  }, []);

  if (isNeedToAuth) {
    return (
      <Authenticator
        variation="modal"
        socialProviders={["amazon", "facebook", "google"]}
      >
        {({ signOut, user }) => (
          <AppContext.Provider
            value={{
              headerHeight,
              sections,
              caseStudies,
              isNeedToAuth,
              setNeedToAuth,
              isExploreCaseStudies,
              setExploreCaseStudies,
              user,
              signOut,
            }}
          >
            <div className="app-content-container">
              <RouterProvider router={router} />
              <FooterComponent />
            </div>
          </AppContext.Provider>
        )}
      </Authenticator>
    );
  }

  return (
    <AppContext.Provider
      value={{
        headerHeight,
        sections,
        caseStudies,
        isNeedToAuth,
        setNeedToAuth,
        isExploreCaseStudies,
        setExploreCaseStudies,
      }}
    >
      <div className="app-content-container">
        <RouterProvider router={router} />
        <FooterComponent />
      </div>
    </AppContext.Provider>
  );
}

export default App;
