import React from "react";
import styles from "./CustomButton.module.css";

const CustomButton = ({
    text,
    style,
    textStyle,
    icon,
    iconStyle,
    onButtonClick,
}) => {
 return (
    <button className={`${styles.button} ${style}`} onClick={onButtonClick}>
        {icon && <img src={icon} alt="Icon" className={iconStyle} />}
        <div className={`${styles.text} ${textStyle}`}>{text}</div>
    </button>
  );;
};

export default CustomButton;