import React from "react";
import CustomButton from "../customButton/CustomButton";
import BackIcon from "../../assets/images/svgs/back_icon.svg";
import GenAILogo from "../../assets/images/svgs/gen_ai_logo.svg";
import InputIcon from "../../assets/images/svgs/input_icon.svg";
import styles from "./NewChatHeaderComponent.module.css";

const NewChatHeaderComponent = ({
  onBackButtonClick,
  onStartNewChatButtonClick,
  onLogoutClick,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <CustomButton
          text="Back"
          icon={BackIcon}
          style={styles.backButton}
          textStyle={styles.backTextStyle}
          iconStyle={styles.iconStyle}
          onButtonClick={onBackButtonClick}
        />
        <div className={styles.logoContainer}>
          <img src={GenAILogo} alt="Icon" className={styles.genAILogo} />
          <div className={styles.logoTitleDiv}>
            <div className={styles.titleContainer}>
              <div className={styles.logoTitle}>A</div>
              <div className={styles.logoTitle}>W</div>
              <div className={styles.logoTitle}>A</div>
              <div className={styles.logoTitle}>I</div>
            </div>
            <div className={styles.heading}>
              ALGOWORKS ARTIFICIAL INTELLIGENCE
            </div>
          </div>
        </div>
        <div className={styles.headerChatContainer}>
          <CustomButton
            text="Start new chat"
            icon={InputIcon}
            style={styles.chatButton}
            textStyle={styles.chatButtonText}
            iconStyle={styles.iconStyle}
            onButtonClick={onStartNewChatButtonClick}
          />
          <CustomButton
            text="logout"
            style={styles.logout}
            textStyle={styles.logoutTextStyle}
            iconStyle={styles.iconStyle}
            onButtonClick={onLogoutClick}
          />
        </div>
      </div>
      <div className={styles.separator} />
    </div>
  );
};

export default NewChatHeaderComponent;
