import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import CustomButton from "../../components/customButton/CustomButton";
import HomeLogoComponent from "../../components/homeLogo/HomeLogoComponent";
import styles from "./HomePage.module.css";
import ChatSquareIcon from "../../assets/images/svgs/chat_square_icon.svg";
import ExploreIcon from "../../assets/images/svgs/explore.svg";

const HomePage = () => {
  const { headerHeight, user, isExploreCaseStudies, setNeedToAuth, setExploreCaseStudies } = React.useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if(user && isExploreCaseStudies) {
      navigate('/case-studies');
      setExploreCaseStudies(false);
    }
  }, [user]);

  const onExploreCaseStudyClick = () => {
    if(user) {
      navigate('/case-studies');
    }
    else {
      setExploreCaseStudies(true);
      setNeedToAuth(true);
    }
  };

  return (
    <div
      className={styles.app}
      style={{ "--dynamic-height": `${headerHeight}px` }}
    >
      <div className={styles.container}>
        <HomeLogoComponent />
        <div className={styles.buttonsDiv}>
          <Link to="/chat" className={styles.link}>
            <CustomButton
              icon={ChatSquareIcon}
              iconStyle={styles.chatIcon}
              text={"Chat with AWAI"}
              style={styles.chatButton}
              textStyle={styles.buttonTitle}
            />
          </Link>
          <div className={styles.caseStudyDiv}>
            <div className={styles.linkContainer}>
              <a
                className={styles.anchor}
                href="https://www.algoworks.com/"
              >
                Return to Website
              </a>
            </div>
            <Link className={styles.caseStudy}>
              <CustomButton
                icon={ExploreIcon}
                text={"Explore Case Study"}
                style={`${styles.chatButton} ${styles.homeButton}`}
                textStyle={styles.buttonTitle}
                onButtonClick={onExploreCaseStudyClick}
              />
            </Link>
          </div>
          <div className={styles.disclaimer}>
            Experience the extraordinary with{" "}
            <span className={styles.appTitle}>AWAI</span>, your Algoworks search
            companion.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
