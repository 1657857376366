import React from "react";

const FooterComponent = () => {
  return (
    <footer id="footer" role="contentinfo" className="footer">
      <section className="home-footer">
        <div className="container">
          <div className="row element-top-40 element-bottom-40 footer-columns-4">
            <div className="footer_row_box">
              <div className="col-md-2 col-sm-3 col-xs-6  text-right order-2">
                <div id="nav_menu-2" className="sidebar-widget  widget_nav_menu">
                  <span className="sidebar-header">Services</span>
                  <div className="menu-footer-services-container">
                    <ul id="menu-footer-services" className="menu">
                      <li
                        id="menu-item-8939"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8939"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/consulting-services/"
                          rel="noreferrer noopener"
                        >
                          Consulting
                        </a>
                      </li>
                      <li
                        id="menu-item-8940"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8940"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/ui-ux/"
                          rel="noreferrer noopener"
                        >
                          UI/UX Design
                        </a>
                      </li>
                      <li
                        id="menu-item-8941"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8941"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/qa-services/"
                          rel="noreferrer noopener"
                        >
                          Quality Assurance
                        </a>
                      </li>
                      <li
                        id="menu-item-8943"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8943"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/custom-app-development/"
                          rel="noreferrer noopener"
                        >
                          Custom Apps
                        </a>
                      </li>
                      <li
                        id="menu-item-8944"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8944"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/custom-solutions/"
                          rel="noreferrer noopener"
                        >
                          Custom Solutions
                        </a>
                      </li>
                      <li
                        id="menu-item-28424"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-28424"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/consulting-services/salesforce-consulting-company/"
                          rel="noreferrer noopener"
                        >
                          Salesforce Services
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-3 col-xs-6 text-right order-3">
                <div id="nav_menu-3" className="sidebar-widget  widget_nav_menu">
                  <span className="sidebar-header">Technologies</span>
                  <div className="menu-footer-technologies-container">
                    <ul id="menu-footer-technologies" className="menu">
                      <li
                        id="menu-item-14978"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-14978"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/mobile-app-development-services/"
                          rel="noreferrer noopener"
                        >
                          App Development
                        </a>
                      </li>
                      <li
                        id="menu-item-8949"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8949"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/web-development-services/"
                          rel="noreferrer noopener"
                        >
                          Web Development
                        </a>
                      </li>
                      <li
                        id="menu-item-8948"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8948"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/salesforce/"
                          rel="noreferrer noopener"
                        >
                          Salesforce
                        </a>
                      </li>
                      <li
                        id="menu-item-28425"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-28425"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/mobile-app-development-services/ios-apps/"
                          rel="noreferrer noopener"
                        >
                          iOS App
                        </a>
                      </li>
                      <li
                        id="menu-item-28426"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-28426"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/mobile-app-development-services/android-apps/"
                          rel="noreferrer noopener"
                        >
                          Android
                        </a>
                      </li>
                      <li
                        id="menu-item-14396"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-14396"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/cloud-computing/"
                          rel="noreferrer noopener"
                        >
                          Cloud Application
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 text-center contacts order-1">
                <div id="text-18" className="sidebar-widget  widget_text">
                  <div className="textwidget">
                    <div className="contact-address">
                      <span className="sidebar-header">Contact Us</span>
                      <span className="sidebar-header-mob">
                        <a href="https://www.algoworks.com/contact-us/">
                          Contact Us &gt;
                        </a>
                      </span>
                      <p>
                        Algoworks Solutions Inc.
                        <br /> 355 W Olive Ave #204
                        <br /> Sunnyvale CA 94086
                        <br />
                        <a href="mailto:sales@algoworks.com" className="mailto">
                          sales@algoworks.com
                        </a>
                        <br />
                        <a className="tell">+1-877-284-1028</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-3 col-xs-6 order-4">
                <div id="nav_menu-4" className="sidebar-widget  widget_nav_menu">
                  <span className="sidebar-header">Trending</span>
                  <div className="menu-footer-company-container">
                    <ul id="menu-footer-company" className="menu">
                      <li
                        id="menu-item-32661"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-32661"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/salesforce-pdo-partner/"
                          rel="noreferrer noopener"
                        >
                          Salesforce PDO
                        </a>
                      </li>
                      <li
                        id="menu-item-28427"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-28427"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/qa-services/security-testing/"
                          rel="noreferrer noopener"
                        >
                          Security Testing
                        </a>
                      </li>
                      <li
                        id="menu-item-11846"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11846"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/cloud-computing/devops-consulting/"
                          rel="noreferrer noopener"
                        >
                          DevOps
                        </a>
                      </li>
                      <li
                        id="menu-item-11847"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-11847"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/mobile-app-development-services/xamarin-apps/"
                          rel="noreferrer noopener"
                        >
                          Xamarin
                        </a>
                      </li>
                      <li
                        id="menu-item-14393"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-14393"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/scripting/angularjs-app-development/"
                          rel="noreferrer noopener"
                        >
                          AngularJS
                        </a>
                      </li>
                      <li
                        id="menu-item-23511"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-23511"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/dell-boomi-partnership/"
                          rel="noreferrer noopener"
                        >
                          Dell Boomi
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-3 col-xs-6 animated order-5">
                <div id="nav_menu-5" className="sidebar-widget  widget_nav_menu">
                  <span className="sidebar-header">Quick Links</span>
                  <div className="menu-footer-quick-links-container">
                    <ul id="menu-footer-quick-links" className="menu">
                      <li
                        id="menu-item-8974"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8974"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/blog/"
                          rel="noreferrer noopener"
                        >
                          Blog
                        </a>
                      </li>
                      <li
                        id="menu-item-13097"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13097"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/mobile-app-portfolio/"
                          rel="noreferrer noopener"
                        >
                          Portfolio
                        </a>
                      </li>
                      <li
                        id="menu-item-29117"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-29117"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/in-the-press/"
                          rel="noreferrer noopener"
                        >
                          In The Press
                        </a>
                      </li>
                      <li
                        id="menu-item-28157"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-28157"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/careers/"
                          rel="noreferrer noopener"
                        >
                          Careers
                        </a>
                      </li>
                      <li
                        id="menu-item-29116"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-29116"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/corporate-social-responsibility/"
                          rel="noreferrer noopener"
                        >
                          CSR Initiatives
                        </a>
                      </li>
                      <li
                        id="menu-item-23755"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-23755"
                      >
                        <a
                          target="_blank"
                          href="https://www.algoworks.com/contact-us/"
                          rel="noreferrer noopener"
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-xs-12 text-center social-footer sidebar-widget"
              // style="width:100%;float:left;"
            >
              <div id="oxywidgetsocial-1" className="sidebar-widget  widget_social">
                <ul className="unstyled inline social-icons social-simple social-normal">
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#3b5998"
                      href="https://www.facebook.com/algoworkstech"
                      rel="noreferrer noopener"
                      // style="background-color: rgba(0, 0, 0, 0);"
                    >
                      <i
                        className="fa fa-facebook"
                        // style="color: rgb(95, 92, 95);"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#34526f"
                      href="https://algoworks.tumblr.com/"
                      rel="noreferrer noopener"
                    >
                      <i className="fa fa-tumblr"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#ea4c89"
                      href="https://dribbble.com/algoworks"
                      // style="background-color: rgba(0, 0, 0, 0);"
                      rel="noreferrer noopener"
                    >
                      <i
                        className="fa fa-dribbble"
                        //   style="color: rgb(95, 92, 95);"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#3b9999"
                      href="https://www.slideshare.net/algoworks"
                      rel="noreferrer noopener"
                    >
                      <i className="fa fa-slideshare"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#00acee"
                      href="https://twitter.com/algoworks"
                      // style="background-color: rgba(0, 0, 0, 0);"
                      rel="noreferrer noopener"
                    >
                      <i
                        className="fa fa-twitter"
                        //   style="color: rgb(95, 92, 95);"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#5FB0D5"
                      href="https://www.linkedin.com/company/algoworks"
                      rel="noreferrer noopener"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#3b9999"
                      href="https://www.reddit.com/user/Algoworks"
                      // style="background-color: rgba(0, 0, 0, 0);"
                      rel="noreferrer noopener"
                    >
                      <i
                        className="fa fa-reddit"
                        //   style="color: rgb(95, 92, 95);"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#3b9999"
                      href="https://mobileappninjas.wordpress.com/"
                      // style="background-color: rgba(0, 0, 0, 0);"
                      rel="noreferrer noopener"
                    >
                      <i
                        className="fa fa-wordpress"
                        //   style="color: rgb(95, 92, 95);"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#3b9999"
                      href="https://www.behance.net/mobileappdevelop"
                      // style="background-color: rgba(0, 0, 0, 0);"
                      rel="noreferrer noopener"
                    >
                      <i
                        className="fa fa-behance"
                        //   style="color: rgb(95, 92, 95);"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#c4302b"
                      href="https://www.youtube.com/c/Algoworks"
                      // style="background-color: rgba(0, 0, 0, 0);"
                      rel="noreferrer noopener"
                    >
                      <i
                        className="fa fa-youtube"
                        //   style="color: rgb(95, 92, 95);"
                        rel="noreferrer noopener"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#910101"
                      href="https://in.pinterest.com/algoworks/"
                      rel="noreferrer noopener"
                    >
                      <i className="fa fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      data-iconcolor="#634d40"
                      href="https://www.instagram.com/algoworks/"
                      // style="background-color: rgba(0, 0, 0, 0);"
                      rel="noreferrer noopener"
                    >
                      <i
                        className="fa fa-instagram"
                        //   style="color: rgb(95, 92, 95);"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section subfooter">
        <div className="container">
          <div className="element-top-10 element-bottom-10 footer-columns-2">
            <div className="col-sm-5">
              <div id="text-4" className="sidebar-widget  widget_text">
                <div className="textwidget">
                  <img
                    src="https://liveimages.algoworks.com/new-algoworks/wp-content/uploads/2021/10/12113557/footer-iso.png"
                    loading="lazy"
                    alt="ISO 27001 Certified"
                    className="img-responsive pull-left"
                    width="93"
                    height="33"
                    //   style="margin:8px 0 0 0;"
                  />
                  <a
                    href="https://www.algoworks.com/corporate-social-responsibility/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src="https://liveimages.algoworks.com/new-algoworks/wp-content/uploads/2020/06/09104247/algo-pledge-badge.png"
                      width="50"
                      height="35"
                      loading="lazy"
                      alt="Pledge 1% Proud Member"
                      className="img-responsive pledge-member pull-left"
                      // style="margin:8px 0 0 10px; margin-left:15px!important; margin-right:12px!important;"
                    />
                  </a>
                  <a
                    href="https://www.dmca.com/Protection/Status.aspx?ID=b12503fa-385a-4443-8b5d-8e5f2dd17504&amp;&amp;refurl=https://www.algoworks.com/"
                    target="_blank"
                    title="DMCA.com Protection Status"
                    className="dmca-badge"
                    rel="noreferrer noopener"
                  >
                    {" "}
                    <img
                      src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-03.png?ID=b12503fa-385a-4443-8b5d-8e5f2dd17504"
                      loading="lazy"
                      width="80"
                      height="48"
                      className="img-responsive pull-left"
                      alt="DMCA.com Protection Status"
                      // style="padding-top:8px;"
                    />
                  </a>
                  <script
                    src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"
                    defer="defer"
                  >
                    {" "}
                  </script>
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              <div id="text-10" className="sidebar-widget  widget_text">
                <div className="textwidget">
                  <ul>
                    <li>© Copyright 2024. All Rights Reserved.</li>
                    <li>
                      <a href="https://www.algoworks.com/privacy-policy/">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="https://www.algoworks.com/terms-of-service/">
                        Terms of Service
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default FooterComponent;
