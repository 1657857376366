import axiosClient, { cancelAllAxiosRequests } from './config';

export const REQ_GET = async(apiPath, config) => {
    return await axiosClient.get(apiPath, config);
};

export const REQ_POST = async(apiPath, data, config) => {
    return await axiosClient.post(apiPath, data, config);
};

export const cancelAllRunningRequests = () => {
    cancelAllAxiosRequests();
}