import axios from 'axios';

const PROTOCOL  = process.env.REACT_APP_PROTOCOL;
const BASE_URL  = process.env.REACT_APP_BASE_URL;

// Function to create a new cancellation token source
const createCancelTokenSource = () => {
  return axios.CancelToken.source();
};

// Function to cancel all pending requests
let cancelTokenSource = createCancelTokenSource();

export const cancelAllAxiosRequests = () => {
  cancelTokenSource.cancel('Request canceled');
  cancelTokenSource = createCancelTokenSource(); // Reset the cancellation token source
};

const baseURL = `${PROTOCOL}://${BASE_URL}`;
const axiosClient = axios.create({
  baseURL,
  timeout: 120000,
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config) {
    config.headers['Content-Type'] = 'application/json';
    config.cancelToken = cancelTokenSource.token;
    return config;
  },
  function (error) {
    // Do something with request error
    console.log('error', error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    try {
      if (response.status === 200) {
        return response.data;
      }
    } catch (_error) {
      return Promise.reject(
        new Error(`Some error occurred ${response.status}`),
      );
    }
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status  === 403) {
      return Promise.reject(new Error(error.response.data.message));
    }
    else if (error.code === 'ECONNABORTED') {
      // Handle timeout error
      return Promise.reject(new Error('Network error.'));
    }
    else {
      return Promise.reject(error);
    }
  },
);

export default axiosClient;
