import React from "react";
import { Link } from "react-router-dom";
// import CustomButton from "../customButton/CustomButton";
import SectionComponent from "../section/SectionComponent";
import InputSearchComponent from "../inputSearch/InputSearchComponent";
import BackIcon from "../../assets/images/svgs/back_icon.svg";
import styles from "./ChatButtonsComponent.module.css";
import { AppContext } from "../../App";
import HomeLogoComponent from "../homeLogo/HomeLogoComponent";
import CustomButton from "../customButton/CustomButton";

const ChatButtonsComponent = ({
  searchText,
  placeholder,
  sendIcon,
  user,
  sections,
  onSearchTextChange,
  onSubmitButtonTapped,
  onSectionItemClick,
  onLogoutClick,
}) => {
  const { headerHeight } = React.useContext(AppContext);

  const onInputTextChange = (event) => {
    handleTextAreaHeight(event);
    onSearchTextChange(event);
  };

  const onHandleKeyDown = (event) => {
    handleTextAreaHeight(event);
  };

  const handleTextAreaHeight = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  const hintStyles =
    sections.length > 1
      ? styles.hintSections
      : `${styles.hintSections} ${styles.hintSection}`;

  return (
    <div
      className={styles.container}
      style={{ "--dynamic-height": `${headerHeight}px` }}
    >
      <div className={styles.backButtonContainer}>
        <Link to={"/"}>
          <CustomButton
            text="Back"
            icon={BackIcon}
            style={styles.backButton}
            textStyle={styles.backTextStyle}
            iconStyle={styles.iconStyle}
          />
        </Link>
        {user && (
          <Link>
            <CustomButton
              text="logout"
              style={styles.logout}
              textStyle={styles.logoutTextStyle}
              iconStyle={styles.iconStyle}
              onButtonClick={onLogoutClick}
            />
          </Link>
        )}
      </div>
      <HomeLogoComponent />
      <InputSearchComponent
        style={styles.input}
        searchText={searchText}
        placeholder={placeholder}
        icon={sendIcon}
        onSearchTextChange={onInputTextChange}
        onHandleKeyDown={onHandleKeyDown}
        onSubmitButtonTapped={onSubmitButtonTapped}
      />
      <div className={styles.footer}>
        <div className={hintStyles}>
          {sections.map((section, idx) => (
            <SectionComponent
              key={`Item_${idx}`}
              section={section}
              style={styles.section}
              onSectionItemClick={onSectionItemClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatButtonsComponent;
