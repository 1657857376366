import React from "react";
import ExampleIcon from "../../assets/images/svgs/example_icon.svg";
import CapabilityIcon from "../../assets/images/svgs/capability_icon.svg";
import LimitationIcon from "../../assets/images/svgs/limitation_icon.svg";
import CaseStudiesIcon from "../../assets/images/svgs/studies.svg";
import defaultStyles from "./SectionComponent.module.css";
import chatStyles from "./ChatSectionComponent.module.css";

const SectionComponent = ({
  style,
  isChatStyle,
  section,
  onSectionItemClick,
}) => {
  const { title, items, icon } = section;
  const styles = isChatStyle ? chatStyles : defaultStyles;
  const isClickable = section.isClickable;

  const getIcon = (icon) => {
    switch (icon) {
      case "examples":
        return ExampleIcon;
      case "capabilities":
        return CapabilityIcon;
      case "limitations":
        return LimitationIcon;
      case "case_study":
        return CaseStudiesIcon;
      default:
        return <ExampleIcon />;
    }
  };
  const getItemComponent = () => {
    return (
      <div className={`${styles.container} ${style}`}>
        <div className={styles.titleContainer}>
          <div className={styles.icon}>
            <img src={getIcon(icon)} alt="icon" />
          </div>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.dataContainer}>
          {items.map((item, index) => {
            if (isClickable) {
              return (
                <button
                  className={styles.sectionClickItem}
                  key={`Item_${index}`}
                  onClick={() => onSectionItemClick(item, section)}
                >
                  <div className={styles.itemTitle}>{item}</div>
                </button>
              );
            }
            return (
              <div
                className={styles.sectionItem}
                key={`Item_${index}`}
                onClick={() => onSectionItemClick(item, section)}
              >
                <div className={styles.itemTitle}>{item}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => getItemComponent(), [section, style]);
};

export default SectionComponent;
