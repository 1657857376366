import React from "react";
import ListItemComponent from "../listItem/ListItemComponent";
import styles from "./ChatListComponent.modules.css";

const ChatListComponent = ({ data }) => {
  const listItems = data.map((item, index) => (
    <ListItemComponent key={`Item_${index}`} item={item} />
  ));

  const getChatListComponent = () => {
    return <ul className={styles.list}>{listItems}</ul>;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => getChatListComponent(), [data]);
};

export default ChatListComponent;
