import React from "react";
import GenAIIcon from "../../assets/images/svgs/gen_ai_logo.svg";
import UserIcon from "../../assets/images/svgs/user_icon.svg";
import LinkIcon from "../../assets/images/svgs/share.svg";
import styles from "./ListItemComponent.module.css";

const ListItemComponent = ({ item }) => {
  const { isAIData, metaData } = item;
  const flexStyle = isAIData ? styles.genAIFlex : styles.userFlex;
  const text = isAIData ? "AWAI" : "You";
  var metaDataKeys = metaData ? Object.keys(metaData) : [];
  const borderStyle = isAIData? styles.dataItemBorder : {};
  const textStyle = isAIData? styles.ai : {};

  const getItemComponent = () => {
    return (
      <li className={`${styles.ulListItem} ${flexStyle}`}>
        <div className={styles.avatarContainer}>
          {isAIData ? (
            <img src={GenAIIcon} alt="Icon" className={styles.avatar} />
          ) : (
            <img
              src={UserIcon}
              alt="Icon"
              className={`${styles.avatar} ${styles.userAvatar}`}
            />
          )}
          <div className={`${styles.user} ${textStyle}`}>{text}</div>
        </div>
        <div className={`${styles.dataItem} ${borderStyle}`}>
          <div className={styles.text}>{item.text}</div>
          {metaData && (
            <div>
              <div className={styles.separator} />
              <div className={styles.text}>Some relevant links I could find for you:</div>
              <div>
                {metaDataKeys.map((key) => {
                  let data = metaData[key];
                  return (
                    <div className={styles.meta}>
                      <a className={styles.anchor} target="_blank" href={data} rel="noreferrer noopener">{key} <img className={styles.link} src={LinkIcon} alt="Icon"  /></a>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </li>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => getItemComponent(), [item]);
};

export default ListItemComponent;
