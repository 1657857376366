import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchQueryData } from "../../api_services/requests/fetchData";
import ChatButtonsComponent from "../../components/chatButtonsComponent/ChatButtonsComponent";
import ChatAIScreen from "../chatAI/ChatAIScreen";
import styles from "./ChatPage.module.css";
import { AppContext } from "../../App";
import NewChatHeaderComponent from "../../components/newChatComponent/NewChatHeaderComponent";
import { cancelAllRunningRequests } from "../../api_services/request";

const ChatPage = () => {
  const [isChatAIOpen, setChatAIOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [apiError, setAPIError] = useState(null);
  const [query, setQuery] = useState(null);
  const [isSectionTapped, setIsSectionTapped] = useState(false);

  const { headerHeight, sections, user, setNeedToAuth, setExploreCaseStudies, signOut } =
    React.useContext(AppContext);
  const navigate = useNavigate();

  const onSearchTextChange = React.useCallback(
    (event) => {
      setSearchText(event.target.value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [searchText, data]
  );

  const onSubmitButtonTapped = React.useCallback(async () => {
    if (!isLoading) {
      const queryText = searchText.trim();
      setSearchText("");
      if (queryText.length > 0) {
        setChatAIOpen(true);
        try {
          const query = {
            text: queryText,
            isAIData: false,
          };
          const newData = [...data];
          newData.push(query);
          setLoading(true);
          setData(newData);
          setQuery(queryText);
          setAPIError(null);
          const response = await fetchQueryData(queryText);
          let metaData = response.metaData;
          const genAIRes = {
            text: response.data.completion,
            isAIData: true,
            metaData,
          };
          const newData1 = [...newData];
          newData1.push(genAIRes);
          setData(newData1);
          setLoading(false);
          setQuery(null);
        } catch (err) {
          if (err.message !== "Request canceled") {
            setAPIError(err);
            setLoading(false);
            setQuery(null);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, data, isLoading]);

  useEffect(() => {
    if (isSectionTapped) {
      setIsSectionTapped(false);
      onSubmitButtonTapped();
    }
  }, [isSectionTapped]);

  const onSectionItemClick = (item, section) => {
    if (section.title === "What can you ask?") {
      setSearchText(item);
      setIsSectionTapped(true);
    }
  };

  const onLogoutClick = () => {
    navigate('/');
    setNeedToAuth(false);
    signOut();
  };

  const onStartNewChatButtonClick = () => {
    cancelAllRunningRequests();
    setData([]);
    setSearchText("");
    setLoading(false);
    setAPIError(null);
    setQuery(null);
  };

  const onBackButtonClick = () => {
    cancelAllRunningRequests();
    setChatAIOpen(false);
    if (isLoading) {
      const newData = [...data];
      const genAIRes = {
        text: "Request canceled by user",
        isAIData: true,
      };
      const newData1 = [...newData];
      newData1.push(genAIRes);
      setData(newData1);
      setSearchText("");
      setLoading(false);
      setAPIError(null);
      setQuery(null);
    }
  };

  return (
    <div
      className={styles.app}
      style={{ "--dynamic-height": `${headerHeight}px` }}
    >
      {isChatAIOpen ? (
        <div className={styles.chatScreen}>
          <NewChatHeaderComponent
            onStartNewChatButtonClick={onStartNewChatButtonClick}
            onBackButtonClick={onBackButtonClick}
            onLogoutClick={onLogoutClick}
          />
          <ChatAIScreen
            searchText={searchText}
            user={user}
            placeholder={"Ready to Explore? Ask anything about Algoworks..."}
            sections={sections}
            data={data}
            queryText={query}
            isLoading={isLoading}
            apiError={apiError}
            onSearchTextChange={onSearchTextChange}
            onSubmitButtonTapped={onSubmitButtonTapped}
            onSectionItemClick={onSectionItemClick}
          />
        </div>
      ) : (
        <ChatButtonsComponent
          searchText={searchText}
          user={user}
          placeholder={"Ready to Explore? Ask anything about Algoworks..."}
          sections={sections}
          onSearchTextChange={onSearchTextChange}
          onSubmitButtonTapped={onSubmitButtonTapped}
          onSectionItemClick={onSectionItemClick}
          onLogoutClick={onLogoutClick}
        />
      )}
    </div>
  );
};

export default ChatPage;
