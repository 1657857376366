import { apiPaths } from "../apiPaths";
import { REQ_POST } from "../request";

export const fetchQueryData = (query, type = 0) => {
  const payload = {
    query,
    type,
  };
  return REQ_POST(apiPaths.dev, payload);
};
