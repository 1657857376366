import React, { useEffect } from "react";
import InputIcon from "../../assets/images/svgs/input_icon.svg";
import SendIcon from "../../assets/images/svgs/send_icon.svg";
import styles from "./InputSearchComponent.module.css";

const InputSearchComponent = ({
  searchText,
  placeholder,
  onSearchTextChange,
  onHandleKeyDown,
  onSubmitButtonTapped,
  textRef,
  style,
  refresh,
  icon,
}) => {
  const handleKeyDown = (event) => {
    onHandleKeyDown(event);
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSubmitButtonTapped && onSubmitButtonTapped();
    }
  };

  const getSearchComponent = () => {
    return (
      <div className={styles.inputContainer}>
        <div className={`${styles.field} ${style}`}>
          <img src={InputIcon} alt="sendIcon" className={styles.iconStyle} />
          <textarea
            type="text"
            rows="1"
            ref={textRef}
            value={searchText}
            placeholder={placeholder}
            onChange={onSearchTextChange}
            onKeyDown={handleKeyDown}
            className={styles.autoResizableTextarea}
          />
          <button onClick={onSubmitButtonTapped}>
            <img src={icon ?? SendIcon} alt="sendIcon" />
          </button>
        </div>
        <div className={styles.disclaimer}>To write multiple lines, simply use Shift+Enter</div>
      </div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => getSearchComponent(), [searchText, refresh]);
};

export default InputSearchComponent;
