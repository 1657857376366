import React from "react";
import styles from "./HomeLogoComponent.module.css";
import GenAILogo from "../../assets/images/svgs/gen_ai_logo.svg";

const HomeLogoComponent = () => {
  const getAppLogo = () => {
    return (
      <div className={styles.logoContainer}>
        <div className={styles.logoTitleContainer}>
          <img src={GenAILogo} alt="Icon" className={styles.genAILogo} />
          <div className={styles.logoTitleDiv}>
            <div className={styles.titleContainer}>
              <div className={styles.logoTitle}>A</div>
              <div className={styles.logoTitle}>W</div>
              <div className={styles.logoTitle}>A</div>
              <div className={styles.logoTitle}>I</div>
            </div>
            <div className={styles.heading}>
              ALGOWORKS ARTIFICIAL INTELLIGENCE
            </div>
          </div>
        </div>
      </div>
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => getAppLogo(), []);
};

export default HomeLogoComponent;
